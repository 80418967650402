import isURL from 'validator/lib/isURL';
import { z } from 'zod';

import { extractPathFromUrl } from '../../extractPathFromUrl';
import { TWEET_URL_META_REGEX } from '../../twitter';
import {
  baseApiTaskSchema,
  baseDateTaskSchema,
  baseDiscordTaskSchema,
  baseFileTaskSchema,
  baseInvitesTaskSchema,
  baseNFTTaskSchema,
  baseNumberTaskSchema,
  baseOnChainTaskSchema,
  baseOpinionTaskSchema,
  basePartnershipTaskSchema,
  basePollTaskSchema,
  baseQuizTaskSchema,
  baseTelegramTaskSchema,
  baseTextTaskSchema,
  baseTiktokTaskSchema,
  baseTokenTaskSchema,
  baseTweetReactTaskSchema,
  baseTweetTaskSchema,
  baseTwitterFollowTaskSchema,
  baseTwitterSpaceTaskSchema,
  baseUrlTaskSchema,
  baseVisitLinkTaskSchema,
  claimQuestStatusSchema,
  nonNegativeIntSchema,
  s3UrlSchema,
  taskValidationErrorCodeSchema,
} from './common';

export const textTaskValueSchema = baseTextTaskSchema.extend({
  value: z.string().trim().min(1),
});

export const urlTaskValueSchema = baseUrlTaskSchema.extend({
  value: z
    .string()
    .url()
    .refine(str => {
      return isURL(str);
    }, 'Invalid URL'),
});

export const quizTaskValueSchema = baseQuizTaskSchema.extend({
  values: z.array(z.string()).min(1),
});

export const pollTaskValueSchema = basePollTaskSchema.extend({
  values: z.array(z.string()).min(1),
});

export const fileTaskValueSchema = baseFileTaskSchema.extend({
  fileUrls: z
    .array(s3UrlSchema)
    .min(1)
    .transform(urls => urls.map(url => extractPathFromUrl(url))),
});

export const dateTaskValueSchema = baseDateTaskSchema.extend({
  value: z.string().datetime(),
});

export const numberTaskValueSchema = baseNumberTaskSchema.extend({
  value: z.number(),
});

export const opinionTaskValueSchema = baseOpinionTaskSchema.extend({
  value: z.number().int(),
});

export const twitterSpaceTaskValueSchema = baseTwitterSpaceTaskSchema.extend({
  password: z.string().optional(),
});

export const tweetTaskValueSchema = baseTweetTaskSchema.extend({
  tweet: z.string().optional(),
  tweetUrl: z.string().regex(TWEET_URL_META_REGEX, 'Invalid tweet URL').optional(), // TODO Remove optional when no more backward compatibility issues
});

export const tweetReactTaskValueSchema = baseTweetReactTaskSchema.extend({
  retweet: z.string().optional(),
  reply: z.string().optional(),
  tweetUrl: z.string().optional(),
});

export const tiktokLinkRegex =
  /^(https?:\/\/)?(www\.|vm\.|vt\.)?tiktok\.com\/(@[\w.-]+\/video\/\d+|[\w/]+)(\?.*)?$/;

export const tiktokTaskValueSchema = baseTiktokTaskSchema.extend({
  value: z.string().url().regex(tiktokLinkRegex, 'Invalid TikTok link'),
});

export const basedTaskValueSchema = z
  .discriminatedUnion('type', [
    basePartnershipTaskSchema,
    baseOnChainTaskSchema,
    baseApiTaskSchema,
    baseNFTTaskSchema,
    baseTokenTaskSchema,
    textTaskValueSchema,
    baseDiscordTaskSchema,
    urlTaskValueSchema,
    baseTelegramTaskSchema,
    quizTaskValueSchema,
    baseInvitesTaskSchema,
    baseVisitLinkTaskSchema,
    fileTaskValueSchema,
    dateTaskValueSchema,
    numberTaskValueSchema,
    pollTaskValueSchema,
    opinionTaskValueSchema,
    baseTwitterFollowTaskSchema,
    twitterSpaceTaskValueSchema,
    tweetReactTaskValueSchema,
    tweetTaskValueSchema,
    tiktokTaskValueSchema,
  ])
  .refine(
    value => {
      const { type } = value;
      if (type === 'tweet') {
        return value.tweet || value.tweetUrl;
      }
      return true;
    },
    {
      message: 'tweetUrl is required',
      path: ['tweetUrl'],
    },
  );

export const claimQuestInputSchema = z.object({
  taskValues: z.array(
    basedTaskValueSchema.and(
      z.object({
        taskId: z.string().uuid(),
      }),
    ),
  ),
  id: z.string().uuid(),
});

export const claimQuestFormSchema = z.record(
  basedTaskValueSchema.and(
    z.object({
      taskId: z.string(),
    }),
  ),
);

const baseErrorSchema = z.object({
  message: z.string().optional(),
});

const invalidTaskErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.INVALID_TASK),
});

const discordErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.DISCORD_USER_NOT_IN_SERVER),
  context: z.object({
    userId: z.string(),
    guildId: z.string().optional(),
  }),
});

const inviteErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.INVITE_FAILED),
  context: z.object({
    activeInviteUserCount: z.number(),
    minInviteUserCount: z.number(),
  }),
});

const wrongAnswerErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.WRONG_ANSWER),
});

const wrongAnswersErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.WRONG_ANSWERS),
});

const opinionErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.OPINION_FAILED),
});

const twitterSpaceErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.WRONG_PASSWORD),
});

const tweetReactErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWEET_REACT_FAILED),
});

const tweetFollowFailSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWITTER_FOLLOW_FAILED),
});

const tweetErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWEET_WORDS_NOT_INCLUDED),
  context: z.object({
    wordsNotIncluded: z.string(),
  }),
});

const tweetEmptyErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWEET_EMPTY),
});

const tweetTooLongErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWEET_TOO_LONG),
});

const tweetUrlInvalidErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWEET_URL_INVALID),
});

const tweetUserMismatch = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWEET_USERNAME_MISMATCH),
  context: z.object({
    connectedAccount: z.string(),
  }),
});

const tweetReplyNotFound = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWEET_REPLY_NOT_FOUND),
  context: z
    .object({
      replyTo: z.string().optional(),
    })
    .optional(),
});

const tweetNotFound = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWEET_NOT_FOUND),
});

const tweetTooOld = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TWEET_CREATED_BEFORE_QUEST),
});

const tiktokWordsNotIncluded = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TIKTOK_WORDS_NOT_INCLUDED),
  context: z.object({
    wordsNotIncluded: z.string(),
  }),
});

const tiktokNotEnoughViews = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TIKTOK_NOT_ENOUGH_VIEWS),
  context: z.object({
    views: z.number(),
    requiredViews: z.number(),
  }),
});

const tiktokNotConnected = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TIKTOK_NOT_CONNECTED),
});

const tiktokUsernameMismatch = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TIKTOK_USERNAME_MISMATCH),
});
const tiktokVideoNotFound = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TIKTOK_VIDEO_NOT_FOUND),
});

const tiktokUrlInvalid = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TIKTOK_URL_INVALID),
});

const apiErrorSchema = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.API_FAILED),
  context: z.object({
    requestId: z.string().optional(),
    message: z.string(),
  }),
});

const walletNotLinked = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.WALLET_NOT_LINKED),
});

const tokenNotFound = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TOKEN_NOT_FOUND),
  context: z.object({
    wallet: z.string(),
  }),
});

const tokenNotEnoughBalance = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.TOKEN_BALANCE_NOT_ENOUGH),
  context: z.object({
    missingTokens: z.number(),
    wallet: z.string(),
  }),
});

const nftNotFound = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.NFT_NOT_FOUND),
  context: z.object({
    wallet: z.string(),
  }),
});

const nftNotEnoughBalance = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.NFT_BALANCE_NOT_ENOUGH),
  context: z.object({
    missingNFTs: z.number(),
    wallet: z.string(),
  }),
});

const partnershipCommunityNotFounds = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.PARTNERSHIP_COMMUNITY_NOT_FOUND),
});

const partnershipUserNotInCommunity = z.object({
  code: z.literal(taskValidationErrorCodeSchema.enum.PARTNERSHIP_USER_NOT_IN_COMMUNITY),
});

const errorSchema = z.discriminatedUnion('code', [
  walletNotLinked,
  tokenNotEnoughBalance,
  tokenNotFound,
  nftNotEnoughBalance,
  nftNotFound,
  partnershipCommunityNotFounds,
  partnershipUserNotInCommunity,
  invalidTaskErrorSchema,
  apiErrorSchema,
  discordErrorSchema,
  wrongAnswerErrorSchema,
  wrongAnswersErrorSchema,
  opinionErrorSchema,
  inviteErrorSchema,
  twitterSpaceErrorSchema,
  tweetErrorSchema,
  tweetNotFound,
  tweetTooOld,
  tweetReplyNotFound,
  tweetReactErrorSchema,
  tweetEmptyErrorSchema,
  tweetTooLongErrorSchema,
  tweetUrlInvalidErrorSchema,
  tweetUserMismatch,
  tweetFollowFailSchema,
  tiktokNotConnected,
  tiktokWordsNotIncluded,
  tiktokVideoNotFound,
  tiktokUsernameMismatch,
  tiktokUrlInvalid,
  tiktokNotEnoughViews,
]);

const statusInReviewSchema = z.object({
  status: z.literal(claimQuestStatusSchema.enum.inReview),
});

const statusSuccessSchema = z.object({
  status: z.literal(claimQuestStatusSchema.enum.success),
});

const statusErrorSchema = z.object({
  status: z.literal(claimQuestStatusSchema.enum.error),
  error: errorSchema.and(baseErrorSchema),
});

export const statusWithErrorSchema = z.discriminatedUnion('status', [
  statusErrorSchema,
  statusSuccessSchema,
  statusInReviewSchema,
]);

export const claimQuestOutputSchema = z.object({
  id: z.string().uuid(),
  questId: z.string().uuid(),
  status: claimQuestStatusSchema,
  taskValidations: z.array(
    statusWithErrorSchema.and(
      z.object({
        taskId: z.string().uuid(),
      }),
    ),
  ),
});

export const claimQuestExportInputSchema = z.object({
  questId: z.array(z.string().uuid()),
  reviewerId: z.array(z.string().uuid()),
  userId: z.array(z.string().uuid()),
  recurrence: z.array(z.enum(['once', 'daily', 'weekly', 'monthly'])),
  status: z.array(z.enum(['fail', 'success', 'pending'])),
});

export const claimQuestReviewInputSchema = z.object({
  claimedQuestIds: z.array(z.string().uuid()).min(1),
  status: z.enum(['fail', 'success']),
  mark: z.enum(['star', 'flag']).optional(),
  comment: z.string().optional(),
  bonusXP: nonNegativeIntSchema.max(1000).optional().default(0),
});

export const claimQuestReviewOutputSchema = z.object({
  reviewedQuests: z.number(),
  status: z.enum(['fail', 'success']),
  mark: z.enum(['star', 'flag']).optional(),
  comment: z.string().optional(),
  rejectedQuests: z
    .array(
      z.object({
        ids: z.array(z.string().uuid()),
        reason: z.string(),
      }),
    )
    .optional(),
});

export type ClaimQuestReviewInput = z.infer<typeof claimQuestReviewInputSchema>;

export type ClaimQuestReviewOutput = z.infer<typeof claimQuestReviewOutputSchema>;

export type ClaimQuestInput = z.infer<typeof claimQuestInputSchema>;

export type ClaimQuestOutput = z.infer<typeof claimQuestOutputSchema>;

export type StatusWithError = z.infer<typeof statusWithErrorSchema>;
